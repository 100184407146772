<template>
<el-dialog
        title="导出公司数据"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="654px"
        @close="handleToClose"
    >
<div v-if="exportStatus === 'success'" class="export-success">
<div v-if="payCurrent !== 'vip'" class="export-success-text">
<i class="el-icon-circle-check"></i>
<span>支付成功</span>
</div>
            
            <div class="export-success-tips">
<span>导出成功，请点击</span>
<router-link to="/user/export" target="_blank">个人中心-导出记录</router-link>
<span>进行文件下载。</span>
</div>
</div>

<div v-else class="export-body">
<div class="total-tips">为你查询到 {{ exportTotal | filterThousandth }} 条客户资源</div>

<div v-loading="loading" class="pay-list">
<template v-for="item in exportPayData">
<div
                        v-if="!item.hidden"
                        :key="item.name"
                        class="pay-item"
                        :class="payItemClass(item)"
                        @click="handleCheckPay(item)"
                    >
<div class="pay-item-label">
<div class="pay-item-text">
<p v-if="item.name === 'novip'" class="vip-text">
<span>开通</span>
<i class="el-icon-vip"></i>
<span>服务</span>
</p>

<p>{{ item.label }}</p>
</div>
</div>
<div class="pay-item-content">
<div class="pay-item-text">
<div class="price">
<span class="currency">￥</span>
<span class="amount">{{ item.amount }}</span>
<span class="unit">/{{ item.unit }}</span>
</div>
<div v-if="item.name === 'vip'" class="notice">今日还可以免费导出{{ vipExportCount }}次</div>
<div v-else class="notice">{{ item.notice }}</div>
</div>
</div>
<div class="pay-item-input">
<div class="radio"><i class="el-icon-check"></i></div>
</div>
</div>
</template>
</div>

<div class="pay-statement">
<div class="pay-statement-content">
<p v-html="payStatement"></p>

<p v-if="payCurrent === 'novip'"><a href="/membership/index" target="_blank">查看更多会员权益>></a></p>
</div>

<div class="pay-statement-kefu">
<el-image src="/images/kf_300_300.png">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>
<p>扫码添加<br />24小时客服在线答疑</p>
</div>
</div>

<div class="export-preview">
<div class="export-preview-tag">导出预览</div>
<div class="export-preview-table">
<img src="/images/export-demo2.jpg" alt="demo">
</div>
</div>

<div class="export-footer">
<div class="export-price">
<span class="label">实付金额：</span>
<span class="currency">￥</span>
<span class="amount">{{ payAmount | filterThousandth }}</span>
</div>
<div class="invoice-tips">支付后可开发票</div>
<div class="pay-btn">
<el-button
                        v-loading="sending"
                        :disabled="loading || payCurrent === 'vip' && vipExportCount <= 0"
                        type="primary"
                        round
                        v-track:click
                        :track-params="'t:' + (payCurrent === 'vip' ? 'click_exportpopup_export' : 'click_exportpopup_payexport')"
                        @click="handleToPay"
                        >{{ payCurrent === 'vip' ? '导出' : '支付并导出' }}</el-button>
</div>
</div>
</div>
</el-dialog>
</template>

<script>
import { ApiVipExport, ApiExportGetExportCount } from '/src/api/export';
import { ApiGetOrderAmount, ApiCreateOrder } from '/src/api/order';

import { getIEVersion } from '/src/utils/index';
import { getPayData, getExportCfg } from '/src/utils/staticData';

import { track } from '/src/directive/track/sendData';

export default {
    name: "CexportCompany",
    props: {
        queryParam: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            sending: false,
            orderCreate: false,

            exportStatus: '',
            exportTotal: 0,
            exportAmount: 0,
            vipExportCount: 0,

            payCurrent: '',
            payStatement: '',
            payAmount: 0,
            exportPayData: [
                {
                    name: 'time',
                    hidden: false,
                    label: '导出约0条数据',
                    amount: 0,
                    unit: '次',
                    notice: '按次购买',
                    statement: '· 导出格式为Excel· 导出数量因查询数据差异性会有上下浮动，以实际导出数量为准\n· 单次导出上限50000条，超过50000条会分批进行下载\n· 该服务属于单次服务，非VIP一样可以使用\n· 支付完成后，可前往<a href="/user/export" target="_blank" class="el-button--text">个人中心-导出记录</a>中下载\n· 该产品为虚拟服务产品，目前不支持退款操作，请谨慎付费',
                },
                {
                    name: 'vip',
                    hidden: true,
                    label: '',
                    amount: 0,
                    unit: '次',
                    notice: '',
                    statement: '',
                },
                {
                    name: 'novip',
                    hidden: true,
                    label: '',
                    amount: 0,
                    unit: '年',
                    notice: '',
                    statement: '',
                }
            ],

            // 会员导出配置
            vipExportCfg: {},

            // 开通类型 1-660元三年会员 2-330元一年会员 3-包季服务 4-一次性导出
            payData: [],
            vipPayData: {},
        };
    },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo || {};
        },
        vipFlag () {
            return this.$store.getters.vipFlag || false;
        },
    },
    // created() {},
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen(total) {
            this.payCurrent = 'time';
            this.exportTotal = total;
            this.exportStatus = '';

            this.dialogVisible = true;
            this.orderCreate = false;

            // 获取用户信息 - 其他客户端可能进行了支付等操作，需要同步信息
            this.$store.dispatch('GetUserInfo').then(() => {
                this.handleGetOrderAmount(total);
            }).catch(() => {});
        },

        // 获取vip数据
        handleVipData() {
            const that = this;
            const { exportPayData, vipExportCfg } = that;

            getPayData((data) => {
                let tmpCurrentItem = {};

                data.forEach((item) => {                    
                    if (item.exportShow) {
                        tmpCurrentItem = item;
                    }
                });

                if (!tmpCurrentItem.guid) {
                    tmpCurrentItem = data[0];
                }
                
                that.payData = data;
                that.vipPayData = tmpCurrentItem;

                exportPayData.forEach((item) => {                    
                    if (item.name === 'novip') {
                        const { exportTimes, exportNumber } = vipExportCfg;
                        let vipUnit = tmpCurrentItem.vipDuration + '个月';

                        if (tmpCurrentItem.vipDuration === 12) {
                            vipUnit = '年';
                        } else if (tmpCurrentItem.vipDuration >= 12 && tmpCurrentItem.vipDuration%12 === 0) {
                            vipUnit = parseInt(tmpCurrentItem.vipDuration / 12) + '年';
                        }

                        item.label = '每天免费导出'+ exportTimes +'次，每次'+ exportNumber +'条数据';
                        item.statement = '· 成为VIP会员，每天免费导出'+ exportTimes +'次，每次最多'+ exportNumber +'条数据\n· VIP会员可在线查看所有客户资源的联系方式（手机、座机、邮箱）\n· VIP会员可使用搜索功能，快速准确找到目标客户资源\n· VIP会员可使用在线收藏功能，对意向客户进行关注\n· 该产品为虚拟服务产品，目前不支持退款操作，请谨慎付费';

                        item.amount = tmpCurrentItem.vipAmount;
                        item.unit = vipUnit;
                        item.notice = '原价￥'+ tmpCurrentItem.beofreAmount +'/' + vipUnit;
                    }
                });
            });
        },

        // 获取会员导出配置数据
        handleExportCfg() {
            const that = this;
            const { exportPayData, vipFlag } = that;            
            const { filterThousandth } = that.$options.filters;

            getExportCfg((data) => {
                const exportTimes = data.exportTimes;
                const exportNumber = filterThousandth(data.exportNumber);

                this.vipExportCfg = {
                    exportTimes,
                    exportNumber,
                };

                exportPayData.forEach((item) => {                    
                    if (item.name === 'vip') {
                        item.label = '只导出'+ exportNumber +'条数据';
                        item.statement = '· 导出格式为Excel\n· 导出数量因查询数据差异性会有上下浮动，以实际导出数量为准\n· 单次导出上限'+ exportNumber +'条，为减少重复导出，可增加筛选维度筛选出更精细的数据\n· 该服务属于VIP服务，每日最多可导出'+ exportTimes +'次\n· 导出成功后，可前往<a href="/user/export" target="_blank" class="el-button--text">个人中心-导出记录</a>中下载';
                    }
                });

                if (!vipFlag) {
                    // 非vip，获取半年的金额
                    this.handleVipData();
                }
            });
        },

        // 显示支付成功
        handlePaySuccess(type) {
            this.payCurrent = type || 'time';
            this.exportStatus = 'success';

            this.dialogVisible = true;
        },

        // 数据初始化
        handleDataFormat(exportAmount) {
            const { vipFlag, payCurrent, exportPayData, exportTotal } = this;
            const { filterThousandth } = this.$options.filters;

            exportPayData.forEach((item) => {
                if (item.name === 'time') {
                    item.label = '导出约'+ filterThousandth(exportTotal) +'条数据';
                    item.amount = filterThousandth(exportAmount);
                } else {
                    item.hidden = (item.name === 'vip' && !vipFlag) || (item.name === 'novip' && vipFlag);
                }

                if (payCurrent === item.name) {
                    this.handleCheckPay(item);
                }
            });

            // 获取会员导出的配置
            this.handleExportCfg();
        },

        // 获取导出数据对应的金额
        handleGetOrderAmount(exportCount) {
            if (this.loading) {
                return false;
            }

            this.loading = true;

            ApiGetOrderAmount({
                exportCount,                
            }).then((res) => {
                this.loading = false;

                this.exportAmount = parseFloat(res.data);
                
                this.handleDataFormat(res.data);

                // 获取会员剩余导出次数
                this.handleGetExportCount();
            }).catch(() => {
                this.loading = false;
            });
        },

        handleCheckPay(item) {
            this.payCurrent = item.name;
            this.payAmount = item.amount;
            this.payStatement = item.statement;
        },

        payItemClass(item) {
            const { payCurrent } = this;
            let tmpClass = ['pay-item__' + item.name];

            if (payCurrent === item.name) {
                tmpClass.push('checked');
            }

            return tmpClass.join(' ');
        },

        handleToPay() {
            const { payCurrent, exportTotal, queryParam, vipPayData } = this;
            let tmpExportCount = exportTotal;
            let tmpOpenType = 4;

            if (payCurrent === 'vip') {
                // vip每次导出1000条
                // tmpExportCount = 1000;
                // this.handleVipExport();

                // 判断浏览器
                const IeV = getIEVersion();

                if (IeV === -1) {
                    this.handleVipExport();
                } else {
                    this.$confirm('为了保障您的下载体验，请使用360浏览器或者chrome浏览器进行操作！', '导出提示', {
                        confirmButtonText: '继续导出',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.handleVipExport();
                    }).catch(() => {});
                }

                return false;
            } else if (payCurrent === 'novip') {
                // 开通VIP
                tmpOpenType = vipPayData.guid;
            }

            if (this.sending) {
                return false;
            }

            this.sending = true;

            ApiCreateOrder({
                exportCount: tmpExportCount,
                // 开通类型 1-660元三年会员 2-330元一年会员 3-包季服务 4-一次性导出
                openType: tmpOpenType,
                queryParam,
            }).then((res) => {
                this.sending = false;
                this.dialogVisible = false;
                this.orderCreate = true;

                this.$emit('confirm', res.data);
            }).catch(() => {
                this.sending = false;
            });
        },

        // 获取会员剩余导出次数
        handleGetExportCount() {            
            const { vipFlag } = this;

            if (this.loading || !vipFlag) {
                return false;
            }

            this.loading = true;

            ApiExportGetExportCount().then((res) => {
                this.loading = false;
                
                this.vipExportCount = res.data || 0;
            }).catch(() => {
                this.loading = false;
            });
        },

        // 会员导出
        handleVipExport() {
            const { queryParam } = this;

            if (this.sending) {
                return false;
            }

            this.sending = true;

            ApiVipExport(queryParam).then(() => {
                this.sending = false;

                this.handlePaySuccess('vip');
            }).catch(() => {
                this.sending = false;
            });
        },

        // 关闭的回调
        handleToClose() {
            if (!this.orderCreate) {
                // 未创建订单，关闭弹出框
                // 上报
                track('t:click_exportpopup_close');
            }
            
            this.orderCreate = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./payList";

// .pay-item-content {
//     width: 190px;
// }

.export-body {
    padding: 10px 40px;
}

.total-tips {
    margin-bottom: 12px;

    font-size: 18px;
    line-height: 24px;
    color: #535353;
    text-align: center;
}

.export-preview {
    position: relative;
    height: 130px;
    margin-bottom: 10px;

    &-link {
        position: absolute;
        top: -34px;
        left: 0;
        z-index: 3;

        .el-button--text {
            font-size: 12px;
        }
    }

    &-tag {
        position: absolute;
        top: -34px;
        right: 90px;
        z-index: 3;

        width: 90px;
        height: 34px;
        background: url(/images/export-tag.png) no-repeat;

        line-height: 26px;
        text-align: center;
        font-size: 12px;
        color: #fff;
    }

    &-table {
        height: 100%;
        background-color: #ddd;

        overflow-x: auto;
        overflow-y: hidden;

        img {
            display: block;
            max-width: inherit;
            width: auto;
            height: 100%;
        }
    }
}

.export-preview-link {
    a {
        color: #E03A3E;
    }
}

.export-footer {
    display: flex;

    line-height: 50px;

    .export-price {
        flex: 1;

        font-size: 18px;
        color: #E03A3E;

        .label {
            padding-left: 2px;
            font-size: 16px;
            color: #474747;
        }
    }

    .invoice-tips {
        width: 100px;

        font-size: 12px;
        color: #535353;
    }

    .pay-btn {
        width: 180px;

        .el-button {
            padding: 0;
            width: 100%;
            height: 50px;
            border-radius: 25px;

            line-height: 50px;
            font-size: 18px;
        }
    }
}

.export-success {
    padding: 48px 0 40px 0;

    text-align: center;
    font-size: 18px;
    color: #1D1D1D;
    line-height: 30px;

    a {
        color: #E03A3E;
    }

    .el-icon-refresh-left {
        margin-right: 5px;
        margin-top: -3px;
        
        font-size: 30px;

        vertical-align: middle;
        
        animation: icoRotate 2s linear infinite;
    }

    &-text {
        margin-bottom: 40px;

        font-size: 24px;
        line-height: 46px;

        .el-icon-circle-check {
            margin-right: 10px;
            margin-top: -4px;

            color: #E03A3E;
            font-size: 46px;

            vertical-align: middle;
        }
    }
}

@keyframes icoRotate {
    0%{-webkit-transform:rotate(360deg);}
    50%{-webkit-transform:rotate(180deg);}
    100%{-webkit-transform:rotate(0deg);}
}

</style>
