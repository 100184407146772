import request from '/src/utils/request';

// 客户列表
export function ApiQueryCompanyList(query) {
    return request({
        url: '/search/queryCompanyList',
        method: 'post',
        data: query,
    });
}

// 跟进收藏
export function ApiCompanyFollow(query) {
    return request({
        url: '/search/follow',
        method: 'post',
        data: query,
    });
}
