<template>
<div class="table-container">
<div class="table-header">
<span class="table-header-text">已为您找到<em>{{ total }}</em>条客户资源</span>
<el-button type="text" class="table-header-cus" v-track:click track-params="t:click_homepage_targetcustomer" @click="handleToNeed">找不到我要的客户？告诉锐眼 >></el-button>

<el-button type="primary" size="small" :disabled="!total" class="table-header-btn" v-track:click track-params="t:click_homepage_export" @click="handleToExprot">导出资源</el-button>
</div>

<div v-loading="loading" class="table-body">
<el-table
                ref="listBodyTable"
                :data="listData"
                stripe
                class="el-table-home"
                @row-click="handleRowClick"
            >
<slot name="table"></slot>

<template v-for="citem in listCfg">
<el-table-column
                        v-if="!citem.hidden"      
                        :key="citem.prop"
                        v-bind="citem"
                        align="center"
                        :resizable="false">
<template slot="header" slot-scope="scope">
<span>{{ scope.column.label }}</span>

<el-popover
                                v-if="citem.popover"
                                placement="bottom"
                                width="320"
                                trigger="hover"
                                class="header-popover">
<div class="header-popover-content">
<p>{{ citem.popover }}</p>

<el-button v-if="!vipFlag" type="primary" class="header-popover-btn" @click="handleToVip">立即开通</el-button>
</div>

<i slot="reference" class="el-icon-question-white"></i>
</el-popover>
</template>

<template slot-scope="scope">
<el-button
                                v-if="citem.type === 'link'"
                                type="text"
                                @click.stop="handleLinkClick(scope.row, citem)"
                                >
<span v-html="scope.row[citem.prop]"></span>
</el-button
                            >

<template
                                v-else-if="citem.buttons && citem.buttons.length"
                            >
<span v-if="citem.prop === 'phones' && vipFlag" class="span-value">{{ handleValueFormat(citem, scope.row) }}</span>

<template v-else>
<el-button
                                        v-for="bitem in citem.buttons"
                                        :key="bitem.name"
                                        :disabled="scope.row[bitem.name + '-disabled']"
                                        :type="bitem.type || 'text'"
                                        :class="bitem.class ? bitem.class : (bitem.name + scope.row[citem.prop])"
                                        @click.stop="handleButtonClick(scope.row, bitem)"
                                        >{{ handleButtonLabel(citem, bitem, scope.row)}}</el-button
                                    >
</template>
</template>

<span v-else class="span-value">{{ handleValueFormat(citem, scope.row) }}</span>
</template>
</el-table-column>
</template>

<template slot="empty">
<div class="list-empty">
<div class="list-empty-pic">
<el-image src="/images/404.png">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>
</div>

<div class="list-empty-content">
<p class="title">抱歉，没有找到相关企业！</p>
<p>· 输入更准确的关键词，重新搜索</p>
<p>· 更换筛选条件，重新搜索</p>
<p>· 扫码添加专属客服跟进该问题</p>
                            
                            <el-image src="/images/kf_300_300.png">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>
</div>
</div>
</template>
</el-table>
</div>

<div class="table-footer">
<Pagination
                :small="false"
                :page="pageNo"
                :limit="pageSize"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
                class="home-pagination"
                :class="{'last-hide': paginationLastHide}"
                @pagination="handlePaginationChange"
            />
</div>
</div>
</template>

<script>
import Pagination from '/src/components/Pagination/index.vue';

import { ApiQueryCompanyList } from '/src/api/search';

import listCfg from './HomeTableCfg';

export default {    
    name: 'homeIndexTable',
    components: {
        Pagination,
    },
    props: {
        listQuery: {
            type: Object,
            default() {
                return {
                    // "cityCode": "1301",
                    // "collectionStatus": "0：有 1：没有",
                    // "emailStatus": "0：有 1：没有",
                    // "estiblishTime": "（1：一年内 2：1-3年 3：3-5年 4：5-10年 5：10-15年 6：15年以上）",
                    // "followStatus": "0：有 1：没有",
                    // "industry": "123",
                    // "offset": 0,
                    // "pageNum": 0,
                    // "pageSize": 0,
                    // "phoneStatus": "0：有 1：没有",
                    // "provinceCode": "13",
                    // "regCapital": "1：50万以内 2：50-100万 3:100-500万 4：500-1000万:5：1000-5000万 6：5000万以上"
                };
            },
        },
    },
    data() {
        return {
            loading: false,

            listData: [],
            listCfg,
            
            pageNo: 1,
            pageSize: 20,
            total: 0,
        };
    },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo || {};
        },
        vipFlag () {
            return this.$store.getters.vipFlag || false;
        },
        staticData () {
            return this.$store.getters.staticData;
        },
        // 最后一个页面隐藏
        paginationLastHide() {
            const { pageNo, pageSize, total } = this;
            const tmpLen = Math.ceil(total / pageSize);

            return (tmpLen - pageNo) > 6;
        },
    },
    // watch: {
    //     listQuery: {
    //         handler(newVal, oldVal) {
    //             // console.log('listQuery', JSON.stringify(newVal) !== JSON.stringify(oldVal));
    //             if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
    //                 // 筛选条件改变时发起请求
    //                 this.handleGetList();
    //             }
    //         },
    //         deep: true,
    //     },
    // },
    // created() {},
    mounted() {
        // 默认发起一次请求
        // this.handleGetList();
    },
    methods: {
        // 获取列表
        handleGetList() {
            const tmpQuery = this.handleQueryFormat();

            if (this.loading) {
                return false;
            }

            this.loading = true;

            // 行业只传一个code即可
            if (tmpQuery.secondIndustryCode) {
                // 如果二级行业有值，转移到一级行业的code
                tmpQuery.industryCode = tmpQuery.secondIndustryCode;
            }

            // console.log(tmpQuery);

            ApiQueryCompanyList(tmpQuery).then((res) => {
                this.loading = false;
                const { currentPage, pageSize, recordCount, companyInfoDto } = res.data;

                // console.log(companyInfoDto);

                this.pageNo = parseInt(currentPage) || 1;
                this.pageSize = parseInt(pageSize) || 20;
                this.total = parseInt(recordCount) || 0;

                this.listData = this.handleDataFormat(companyInfoDto || []);

                this.handleToEmit('ready');
            }).catch(() => {
                this.loading = false;

                this.handleToEmit('ready');
            });
        },
        // 筛选数据
        handleQueryFormat() {
            const { listQuery, pageNo, pageSize, } = this;

            // // 未登录，筛选条件情况
            // if (true) {
            //     return {
            //         pageNum: pageNo,
            //         pageSize,
            //     };
            // }

            return {
                pageNum: pageNo,
                pageSize,
                ...listQuery,
            };
        },
        // 数据格式化
        handleDataFormat(data) {
            const { staticData, vipFlag } = this;

            data.forEach((item) => {
                // 省份、城市信息拼接，详情展示需要
                item.provinceCity = [item.province, item.city].join(' ');

                // 跟进状态 - 转为整数方便操作
                item.followStatus = parseInt(item.followStatus || 0);
                item.followLabel = staticData.follow[item.followStatus];

                // 收藏状态
                item.collectionStatus = parseInt(item.collectionStatus || 0);
                item.collectionLabel = staticData.collection[item.collectionStatus];
                
                // 添加用户登录信息及vip，用于列表展示及操作
                item.vip = vipFlag;                
            });

            // console.log(data);

            return data;
        },

        // 选中行 - 打开详情
        handleRowClick(row, column, event) {
            this.handleToEmit('row', {
                row,
                column,
            });

            event.stopPropagation();
        },

        // 点击链接
        handleLinkClick(row, item) {
            this.handleToEmit('link', {
                row,
                item,
            });
        },

        // 点击按钮
        handleButtonClick(row, bitem) {
            // if (bitem.name === 'collect') {
            //     row.collect = !row.collect;
            // }

            // 按钮是否显示或是否可点击
            // 在获取列表数据之后格式化数据，设置 '[prop]-show' 和 '[prop]-disabled' 来设置勾选框是否可选，默认值都为false
            this.handleToEmit('button', {
                row,
                bitem,
            });
        },

        // 返回按钮文字
        handleButtonLabel(item, bitem, row) {
            if (typeof bitem.format === 'function') {
                return bitem.format(item, bitem, row);
            }
            
            return bitem.label;
        },

        // 输出值格式化
        handleValueFormat(citem, row) {
            const { prop, format, values } = citem;
            const { filterThousandth, filterDate } = this.$options.filters;
            let tmpValue = row[prop];

            if (typeof(format) === 'function') {
                return format(tmpValue);
            }

            switch(format) {
                case 'number':
                    tmpValue = filterThousandth(tmpValue);
                    break;
                case 'date':
                    tmpValue = filterDate(tmpValue, citem['value-format'] || 'YYYY-MM-DD');
                    break;                
                case 'radio':
                    tmpValue = values ? (values[tmpValue] || '-') : '-';
                    break;
                default:
                    break;
            }

            // console.log(format, tmpValue);

            return tmpValue;
        },

        // 页码变化
        handlePaginationChange(data) {
            const { page, limit } = data;
            
            this.pageNo = page;
            this.pageSize = limit;
            
            this.handleGetList();
        },

        // 设置客户搜索偏好
        handleToNeed() {
            this.handleToEmit('need');
        },

        // 开通vip
        handleToVip() {
            this.handleToEmit('vip');
        },

        // 导出
        handleToExprot() {
            const { total } = this;

            this.handleToEmit('export', {
                total,
            });
        },

        // 事件监听
        handleToEmit(type, data) {
            this.$emit('change', {
                type,
                data,
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "./HomeTable";
</style>